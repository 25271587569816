import React, { useState } from 'react';

import './styles.scss';
import Card from "../Card/Card"
import img from "../../images/newsroom.jpg"
import Social from "../Social/Social"
import axios from "axios";
import { RiSendPlane2Fill } from 'react-icons/ri';

const News = () => {
  const style = {
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    overflow: "hidden",
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }

  const FormOk = () => {
    return (
      <>
        <div class="alert alert-primary" role="alert">
          Got this! You are one step closer.
        </div>
      </>
    )
  }

  const FormError = () => {
    return (
      <>
        <div class="alert alert-warning" role="alert">
          Oops.. Something went wrong with the sending form.
        </div>
      </>
    )
  }

  const [serverState, setServerState] = useState({
    submitting: null,
    status: null
  });
  const handleServerResponse = (ok, form) => {
    setServerState({
      submitting: null,
      status: ok,
      submited: true,
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true, submited: true });
    axios({
      method: "post",
      url: "https://getform.io/f/1db7a86a-0f42-4a2a-9570-fe7e7c12bc1b",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, form, true);
      })
      .catch(r => {
        handleServerResponse(false, form, true);
      });
  };

  return (
    <>
        <section className="hero_image newsroom" style={style}>
            <div className="overlay"></div>
            <div className="content">
              <h2 className="text-center title">Newsroom</h2>
              <p className="text-center paragraph"> Σημείο νέων <span className="blue-text">nowCaptain</span>. <br /> Μείνετε συνδεδεμένοι μαζί μας για περισσότερα νέα.</p>
              {/*<div className="newsletter-form">*/}
              {/*  <h5 className="newsletter d-block">Εγγραφείτε στο newsletter μας</h5>*/}
              {/*{serverState.submited ?*/}
              {/*  <div className="alert">*/}
              {/*    {serverState.status === true ? <FormOk /> : null}*/}
              {/*    {serverState.status === false ? <FormError /> : null}*/}
              {/*  </div>*/}
              {/*  :*/}
              {/*  <form className="form-inline contact-form" onSubmit={handleOnSubmit}>*/}
              {/*    <div className="form-group">*/}
              {/*      <input type="email" name="email" className="form-control" aria-describedby="emailHelp" id="exampleInputName" placeholder="Το email σου" required="required" />*/}
              {/*    </div>*/}
              {/*    <button type="submit" className="btn" disabled={serverState.submitting}>*/}
              {/*      <RiSendPlane2Fill size={20} />*/}
              {/*    </button>*/}
              {/*  </form>*/}
              {/*}*/}
              {/*</div>*/}
              {/*<p className="text-center social-text">Ακολούθηστε το nowCaptain</p>*/}
              <div className="d-flex justify-content-center">
                <Social />
              </div>
            </div>
          </section>
    </>
  )
}

export default News;